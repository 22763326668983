
import React, { useRef } from "react";
import { AnnonceDateView } from "../../../../common/annonce-date-view/annonce-date-view";
import { Divider } from "../../../../common/divider/divider";
import { ShareAnnonceModal } from "../../../../components/modals/share-annonce-modal/share-annonce-modal";
import { resources } from "../../../../constants/hardcoded-string";
import useScript from "../../../../hooks/use-script";
import { SimpleAnnonce } from "../../../../types/annonce";
import { FormattedAnnonce } from "../../../../types/formatted-annonce";
import { RelatedAnnoncesComponent } from "./related-annonces-component/related-annonces-component";
import { DEFAULT_IMAGE_ANNOUNCEMENT } from "constants/globals";

type AnnonceDetailsComponentProps = {
    formattedAnnonceDetails: FormattedAnnonce;
    relatedAnnonces: Array<SimpleAnnonce>;
}

export const AnnonceDetailsComponent = ({ formattedAnnonceDetails, relatedAnnonces }: AnnonceDetailsComponentProps) => {
    const galleryDetailsRef = useRef(null);
    const galleryThumbRef = useRef(null);

    useScript("/js/views/view.shop.js");
    useScript("/js/examples/examples.gallery.js");

    const annonceDetailsResources = resources.pages.annonceDetails;
    document.title = `Annonce - ${formattedAnnonceDetails.title}`;
    const descriptionHtml = { __html: formattedAnnonceDetails.description };

    const annonceDetailsLink = window.location.href;

    return <div className="container py-4">
        <div className="container">
            <div className="row">
                <div className="col-md-6 mb-5 mb-md-0">
                    <div className="thumb-gallery-wrapper">
                        <div ref={galleryDetailsRef} className="thumb-gallery-detail owl-carousel owl-theme manual nav-inside nav-style-1 nav-dark mb-3">
                            {formattedAnnonceDetails.images.length === 0 && (
                                  <img alt="" className="img-fluid" src={DEFAULT_IMAGE_ANNOUNCEMENT} data-zoom-image={`${DEFAULT_IMAGE_ANNOUNCEMENT}`} />
                            )}
                            {formattedAnnonceDetails.images.map((image, index) => {
                                return (<div key={index}>
                                    <img alt="" className="img-fluid" src={image.path} data-zoom-image={`${image.path}`} />
                                </div>);
                            })}


                        </div>
                        <div ref={galleryThumbRef} className="thumb-gallery-thumbs owl-carousel owl-theme manual thumb-gallery-thumbs">
                            {formattedAnnonceDetails.images.map((image, index) => {
                                return (<div key={index} className="cur-pointer">
                                    <img alt="" className="img-fluid" src={image.path} data-zoom-image={`${image.path}`} />
                                </div>);
                            })}
                        </div>
                    </div>
                </div>

                <div className="col-md-6">
                    <div className="summary entry-summary position-relative">
                        <div className="mb-3">
                            <span className="badge badge-success badge-md">{formattedAnnonceDetails.property_type.label}</span>
                            <span className="badge badge-primary badge-md">{formattedAnnonceDetails.annonce_type.label}</span>
                        </div>
                        <AnnonceDateView updated_at={formattedAnnonceDetails.updated_at} created_at={formattedAnnonceDetails.created_at} />
                        <h3 className="price mb-3">
                            <span className="sale text-color-dark">{`${formattedAnnonceDetails.price}  FCFA`}</span>
                        </h3>



                        {formattedAnnonceDetails.annonce_specification.value.features.length > 0 && <div>

                            {
                                formattedAnnonceDetails.annonce_specification.value.features.map((commodite, index) => {
                                    return <span key={index} className="badge badge-dark badge-sm rounded-pill text-uppercase px-2 py-1 me-1">{commodite.value}</span>
                                })
                            }
                        </div>}

                        <ul className="list list-unstyled text-2">
                            <li className="mb-0">DISPONIBILITE: <strong className="text-color-dark">{formattedAnnonceDetails.status === "active" ? "DISPONIBLE" : " PLUS DISPONIPLE"}</strong></li>
                        </ul>
                        <div className="quantity quantity-lg">
                            <div className="d-flex align-items-center">
                                <button className="btn btn-modern btn-primary" data-bs-toggle="modal" data-bs-target="#share-annonce-link-modal">
                                    {resources.modals.shareAnnonceModal.title}
                                </button>

                                {<ShareAnnonceModal link={annonceDetailsLink} />}
                            </div>
                        </div>

                    </div>
                </div>
                <Divider />


                <div className="row">
                    <div className="col-lg-12">
                        <h4 className="font-weight-bold mb-3">{annonceDetailsResources.description.title}</h4>
                        <hr className="mt-0 mb-3" />

                        <div className="text-3-5 mb-3" dangerouslySetInnerHTML={descriptionHtml}></div>
                    </div>

                </div>


                {formattedAnnonceDetails.annonce_specification.value.overview.length > 0 && <><Divider />
                    <div className="row">
                        <h4 className="font-weight-bold mb-3">{annonceDetailsResources.overview.title}</h4>
                        <hr className="mt-0 mb-3" />

                        {
                            formattedAnnonceDetails.annonce_specification.value.overview.map((caracteristique, index) => {
                                return <div key={index}>
                                    <span className="font-weight-bold">{caracteristique.key}</span>
                                    <span>{" : "}</span>
                                    <span className="ml-3">{caracteristique.value}</span>
                                </div>

                            })
                        }
                    </div></>}


                {relatedAnnonces.length > 0 && <><Divider /> <RelatedAnnoncesComponent relatedAnnonces={relatedAnnonces} /></>}

                {/* <section className="section-custom-medical">
                    <div className="container">
                        <div className="row medical-schedules">
                            <div className="col-xl-3 box-one bg-color-primary appear-animation" data-appear-animation="fadeInLeft" data-appear-animation-delay="0">
                                <div className="feature-box feature-box-style-2 align-items-center p-4">
                                    <div className="feature-box-icon p-0">
                                        <img src="/img/demos/medical/icons/medical-icon-heart.png" alt="" className="img-fluid pt-1" />
                                    </div>
                                    <div className="feature-box-info">
                                        <h4 className="m-0 p-0">Medical Treatment</h4>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-3 box-two bg-color-tertiary appear-animation" data-appear-animation="fadeInLeft" data-appear-animation-delay="600">
                                <h5 className="m-0">
                                    <a href="demo-medical-doctors.html" title="">
                                        Doctors Timetable
                                        <i className="icon-arrow-right-circle icons"></i>
                                    </a>
                                </h5>
                            </div>
                            <div className="col-xl-3 box-three bg-color-primary appear-animation" data-appear-animation="fadeInLeft" data-appear-animation-delay="1200">
                                <div className="expanded-info p-4 bg-color-primary">
                                    <div className="info custom-info">
                                        <span>Mon-Fri</span>
                                        <span>8:30 am to 5:00 pm</span>
                                    </div>
                                    <div className="info custom-info">
                                        <span>Saturday</span>
                                        <span>9:30 am to 1:00 pm</span>
                                    </div>
                                    <div className="info custom-info">
                                        <span>Sunday</span>
                                        <span>Closed</span>
                                    </div>
                                </div>
                                <h5 className="m-0">
                                    Opening Hours
                                    <i className="icon-arrow-right-circle icons"></i>
                                </h5>
                            </div>
                            <div className="col-xl-3 box-four bg-color-secondary p-0 appear-animation" data-appear-animation="fadeInLeft" data-appear-animation-delay="1800">
                                <a href="tel:+008001234567" className="text-decoration-none">
                                    <div className="feature-box feature-box-style-2 m-0">
                                        <div className="feature-box-icon">
                                            <i className="icon-call-out icons"></i>
                                        </div>
                                        <div className="feature-box-info">
                                            <label className="font-weight-light">Emergency case</label>
                                            <strong className="font-weight-normal">(800)123-4567</strong>
                                        </div>
                                    </div>
                                </a>
                            </div>
                        </div>

                    </div>
                </section> */}
            </div>
        </div>

    </div>
}